.ColorBlock {
    display: inline-flex;
    width: 8px * 4;
    height: 8px * 4;
    box-sizing: border-box;
    border: 2px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 0 0 0 1px rgba(#ECECEC, 0.3);
    position: relative;

    &-remove {
        box-sizing: content-box;
        position: absolute;
        background-image: url('../icons/cross.svg');
        background-size: cover;
        background-repeat: no-repeat;
        right: -2px;
        top: -2px;
        border: 2px solid transparent;
        width: 12px;
        height: 12px;
        visibility: hidden;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }
    
    &--new {
        position: relative;
        border-color: rgb(245, 244, 244);
        background-color: rgb(245, 244, 244) !important;
        background-image: url('../icons/plus.svg');
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &:hover {
        .ColorBlock-remove {
            visibility: visible;
        }
    }

    &.is-active {
        border-color: #FFF;
        box-shadow: 0 0 6px#ECECEC;
    }
}