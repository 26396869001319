.ColorSchemePicker {
    display: inline-block;
    position: relative;

    &-palette {
        display: flex;
        flex-wrap: wrap;
    }

    &-colorBlock {
        margin-right: 6px;
        margin-bottom: 6px;
    }

    &-colorPicker {
        position: absolute;
        z-index: 100;
    }
}
