@import "components/ColorSchemePicker";
@import "components/ColorPicker";
@import "components/ColorBlock";

.Editor {
    height: 100%;
    display: flex;

    &-sidebar {
        box-sizing: border-box;
        width: 300px;
        flex: 0 0 300px;
        border-right: 1px solid #ddd;
        display: flex;
        flex-direction: column;
    }

    &-controls {
        padding: 16px;
        flex: 1 1 0;
        overflow: auto;
        min-height: 0;
    }

    &-controlsBottom {
        border-top: 1px solid #ddd;
        padding: 16px;
        flex: 0 0 0;
    }

    &-canvas {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;

        > * {
            flex: 1 1 0;
        }
    }

    &-controlsDownload {

    }
}